var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"opacity":0.5,"show":_vm.loading,"rounded":"sm","blur":'true'}},[_c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('curfox-logo'),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" Curfox ")])],1),_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Welcome to Curfox! 👋 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Please Enter Your Business Details! ")]),_c('validation-observer',{ref:"registerForm",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.validationForm)}}},[_c('b-form-group',{staticClass:"required",attrs:{"label":"Business name","label-for":"register-businessname"}},[_c('validation-provider',{attrs:{"name":"Businessname","vid":"businessname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-businessname","name":"register-businessname","state":errors.length > 0 ? false:null,"placeholder":" "},model:{value:(_vm.form.businessName),callback:function ($$v) {_vm.$set(_vm.form, "businessName", $$v)},expression:"form.businessName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"required",attrs:{"label":"Country","label-for":"register-country"}},[_c('validation-provider',{attrs:{"name":"Country","vid":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"register-country","name":"register-country","options":_vm.countries,"state":errors.length > 0 ? false:null,"placeholder":"Select country"},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"required",attrs:{"label":"Domain name","label-for":"register-domainname"}},[_c('validation-provider',{attrs:{"name":"Domainname","vid":"domainname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex"},[_c('b-form-input',{staticClass:"subDomain",attrs:{"id":"subDomain","name":"subDomain","disabled":""},model:{value:(_vm.subDomain),callback:function ($$v) {_vm.subDomain=$$v},expression:"subDomain"}}),_c('b-form-input',{attrs:{"id":"domain-name","type":"text","placeholder":"domain name"},on:{"keyup":function($event){return _vm.checkDomainEnd()}},model:{value:(_vm.form.domainName),callback:function ($$v) {_vm.$set(_vm.form, "domainName", $$v)},expression:"form.domainName"}}),_c('b-form-input',{staticClass:"topDomain",attrs:{"id":"topLevelDomain","name":"topLevelDomain","disabled":""},model:{value:(_vm.topLevelDomain),callback:function ($$v) {_vm.topLevelDomain=$$v},expression:"topLevelDomain"}})],1),(_vm.domainName && _vm.domainNameError)?_c('p',{staticClass:"error",class:{ 'text-danger': errors.length }},[_vm._v(" "+_vm._s(_vm.domainNameError)+" ")]):_vm._e(),(_vm.displayDomain)?_c('p',{class:_vm.isValidDomain ? 'text-success' : 'text-danger'},[_vm._v(_vm._s(_vm.displayDomain))]):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"required",attrs:{"label":"Admin full name","label-for":"register-adminname"}},[_c('validation-provider',{attrs:{"name":"Adminname","vid":"adminname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-adminname","name":"register-adminname","state":errors.length > 0 ? false:null,"placeholder":" "},model:{value:(_vm.form.adminFullName),callback:function ($$v) {_vm.$set(_vm.form, "adminFullName", $$v)},expression:"form.adminFullName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{staticClass:"required",attrs:{"label":"Admin contact number","label-for":"register-admincontact"}},[_c('validation-provider',{attrs:{"name":"Admincontact","vid":"admincontact","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-admincontact","name":"register-admincontact","type":"number","autocomplete":"nope"},model:{value:(_vm.form.adminContactNumber),callback:function ($$v) {_vm.$set(_vm.form, "adminContactNumber", $$v)},expression:"form.adminContactNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-overlay',{attrs:{"opacity":0.5,"show":_vm.loading,"rounded":"sm","blur":'true'}},[_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":_vm.is_disable}},[_vm._v(" Sign Up ")])],1)],1)]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }