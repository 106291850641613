<template>
  <b-overlay
    :opacity="0.5"
    :show="loading"
    rounded="sm"
    :blur="'true'"
  >
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">
        <!-- Login v1 -->
        <b-card class="mb-0">
          <b-link class="brand-logo">
            <curfox-logo />

            <h2 class="brand-text text-primary ml-1">
              Curfox
            </h2>
          </b-link>

          <b-card-title class="mb-1">
            Welcome to Curfox! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            Please Enter Your Business Details!
          </b-card-text>
          <validation-observer
            ref="registerForm"
            v-slot="{ handleSubmit }"
            slim
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="handleSubmit(validationForm)"
            >

              <!-- Business name -->
              <b-form-group
                class="required"
                label="Business name"
                label-for="register-businessname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Businessname"
                  vid="businessname"
                  rules="required"
                >
                  <b-form-input
                    id="register-businessname"
                    v-model="form.businessName"
                    name="register-businessname"
                    :state="errors.length > 0 ? false:null"
                    placeholder=" "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Country-->
              <b-form-group
                class="required"
                label="Country"
                label-for="register-country"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Country"
                  vid="country"
                  rules="required"
                >
                  <v-select
                    id="register-country"
                    v-model="form.country"
                    name="register-country"
                    :options="countries"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Select country"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!--Domain name-->
              <b-form-group
                class="required"
                label="Domain name"
                label-for="register-domainname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Domainname"
                  vid="domainname"
                  rules="required"
                >
                  <div class="d-flex">
                    <b-form-input
                      id="subDomain"
                      v-model="subDomain"
                      name="subDomain"
                      class="subDomain"
                      disabled
                    />
                    <b-form-input
                      id="domain-name"
                      v-model="form.domainName"
                      type="text"
                      placeholder="domain name"
                      @keyup="checkDomainEnd()"
                    />
                    <b-form-input
                      id="topLevelDomain"
                      v-model="topLevelDomain"
                      name="topLevelDomain"
                      class="topDomain"
                      disabled
                    />
                  </div>
                  <p
                    v-if="domainName && domainNameError"
                    class="error"
                    :class="{ 'text-danger': errors.length }"
                  >
                    {{ domainNameError }}
                  </p>
                  <p
                    v-if="displayDomain"
                    :class="isValidDomain ? 'text-success' : 'text-danger'"
                  >{{ displayDomain }}</p>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>

              <!--Admin name-->
              <b-form-group
                class="required"
                label="Admin full name"
                label-for="register-adminname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Adminname"
                  vid="adminname"
                  rules="required"
                >
                  <b-form-input
                    id="register-adminname"
                    v-model="form.adminFullName"
                    name="register-adminname"
                    :state="errors.length > 0 ? false:null"
                    placeholder=" "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!--Admin contact-->
              <b-form-group
                class="required"
                label="Admin contact number"
                label-for="register-admincontact"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Admincontact"
                  vid="admincontact"
                  rules="required"
                >
                  <b-form-input
                    id="register-admincontact"
                    v-model="form.adminContactNumber"
                    name="register-admincontact"
                    type="number"
                    autocomplete="nope"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Next buttons -->
              <b-overlay
                :opacity="0.5"
                :show="loading"
                rounded="sm"
                :blur="'true'"
              >
                <b-button
                  variant="primary"
                  block
                  type="submit"
                  :disabled="is_disable"
                >
                  Sign Up
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>
        </b-card>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton, BForm, BFormInput, BFormGroup, BCard, BLink, BCardTitle, BCardText, BOverlay,
} from 'bootstrap-vue'
import CurfoxLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import Ripple from 'vue-ripple-directive'
import 'animate.css'

const RegisterRepository = RepositoryFactory.get('googleaccount')
const AuthRepository = RepositoryFactory.get('auth')

export default {
  components: {
    // BSV
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BLink,
    BOverlay,
    CurfoxLogo,
    vSelect,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility, SuccessMessage, ErrorMessage],
  data() {
    return {
      required,
      email,
      countries: [],
      form: {
        businessName: '',
        country: '',
        domainName: '',
        adminFullName: '',
        adminContactNumber: '',
      },
      loading: false,
      msg: '',
      is_disable: true,
      showPleaseWait: false,
      typingTimer: null,
      subDomain: 'www.',
      topLevelDomain: '.com',
      isValidDomain: true,
    }
  },
  computed: {
    domainNameError() {
      const domainNameRegex = /[^\w\s.]/gi
      if (domainNameRegex.test(this.form.domainName)) {
        return 'Please enter a valid domain name (alphanumeric characters, hyphens, and dots only).'
      }
      return ''
    },
    displayDomain() {
      let domain = this.form.domainName
      if (domain && !this.domainNameError) {
        domain = domain.replace(/^www\./, '').replace(/\.lk$/, '')
        return `https://www.${domain}.curfox.com`
      }
      return ''
    },
    assignDomain() {
      let domain = this.form.domainName
      if (domain && !this.domainNameError) {
        domain = domain.replace(/^www\./, '').replace(/\.lk$/, '')
        return domain
      }
      return ''
    },
  },
  created() {
    this.redirectIfDirectAccess()
    this.getCities()
  },
  methods: {
    async getCities() {
      await fetch('https://restcountries.com/v3.1/all')
        .then(response => response.json())
        .then(data => {
          const countries = data.map(country => country.name.common)
          countries.sort()
          this.countries = countries
        })
        .catch(error => this.showErrorMessage(error))
    },
    redirectIfDirectAccess() {
      if (!localStorage.getItem('isEmailVerified')) {
        this.$router.push('/buisness-details')
      }
    },
    async validationForm() {
      this.loading = true
      if (await this.$refs.registerForm.validate()) {
        // eslint-disable-next-line no-underscore-dangle
        const payload = {
          email: localStorage.getItem('email'),
          admin_full_name: this.form.adminFullName,
          business_name: this.form.businessName,
          domain_name: this.assignDomain,
          country: this.form.country,
          contact_number: this.form.adminContactNumber,
        }
        this.sendUserBusinessDetails(payload)
      }
      this.loading = false
    },
    async sendUserBusinessDetails(payload) {
      this.loading = true
      this.showPleaseWaitMessage()
      try {
        const res = await RegisterRepository.sendUserBusinessDetails(payload)
        if (res.status === 200) {
          this.showSuccessMessage(res.data.message)
          const redirecturl = `${res.data.domain}/login?email=${localStorage.getItem('email')}&token=${res.data.token}`
          window.location.href = redirecturl
        } else {
          this.showErrorMessage(res.data.message)
        }
        this.hidePleaseWaitMessage()
        this.loading = false
      } catch (error) {
        this.hidePleaseWaitMessage()
        this.loading = false
        if (error.response && error.response.data && error.response.data.message) {
          this.convertAndNotifyError(error)
        } else {
          this.showErrorMessage('An error occurred while business registering.')
        }
      }
    },
    async checkDomainEnd() {
      clearTimeout(this.typingTimer)
      // Start a new timer to wait for 1.2 second after the last keyup event
      this.typingTimer = setTimeout(async () => {
        await this.checkDomain()
      }, 1200)
    },
    async checkDomain() {
      try {
        // Delay the execution by 1.2 second to allow the user to finish typing
        await new Promise(resolve => setTimeout(resolve, 1200))
        const payload = {
          domain_name: this.assignDomain,
        }
        const res = await AuthRepository.domainValidation(payload)
        if (res.status === 200) {
          this.is_disable = false
          this.isValidDomain = true
          this.showSuccessMessage(res.data.message)
        } else if (res.status === 400) {
          this.is_disable = true
          throw new Error(res.data.message)
        }
      } catch (error) {
        this.is_disable = true
        if (this.assignDomain) {
          this.isValidDomain = false
        } else {
          this.isValidDomain = true
        }
        if (error.response && error.response.data && error.response.data.message) {
          if (error.response.data.code) {
            this.showErrorMessage(error.response.data.message)
          } else {
            this.showErrorMessage(error.response.data.errors.domain_name[0])
          }
        } else {
          this.showErrorMessage('An error occurred while domain name verifying.')
        }
      }
    },
    showPleaseWaitMessage() {
      this.$swal({
        title: 'Please Wait..!',
        text: 'Registering your business. Please wait and avoid page refresh or going back. You will be redirected to your account shortly. Thank you!',
        // eslint-disable-next-line global-require
        imageUrl: require('@/assets/images/slider/animate.gif'),
        imageWidth: 200,
        imageHeight: 200,
        imageAlt: 'Custom image',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        showConfirmButton: false, // Remove the confirm button
        allowOutsideClick: false, // Prevent closing by clicking outside
        allowEscapeKey: false,
      })
    },
    hidePleaseWaitMessage() {
      if (this.$swal) {
        this.$swal.close() // Close the Swal instance
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/required.scss';
.subDomain {
  width: 30%;
  margin-right: 5px;
}
.topDomain {
  width: 30%;
  margin-left: 5px;
}
</style>
